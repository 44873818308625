<template>
  <table class="table">
    <thead>
    <tr>
      <th>{{ $t('exam-schedule.subject') }}</th>
      <th>{{ $t('exam-schedule.time') }}</th>
<!--      <th style="width: 14%">{{ $t('exam-schedule.coming-time') }}</th>-->
      <th>{{ $t('exam-schedule.day') }}</th>
      <th>{{ $t('exam-schedule.date') }}</th>
      <th>{{ $t('exam-schedule.place') }}</th>
      <th>{{ $t('exam-schedule.room') }}</th>
      <th>{{ $t('exam-schedule.type') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="exam of exams">
      <td>{{ helpers.getLocalizedField(exam, 'subject') }}</td>
      <td>{{ exam.exam_time }}</td>
<!--      <td>{{ exam.comming_time }}</td>-->
      <td>{{ helpers.getLocalizedField(exam, 'day') }}</td>
      <td>{{ exam.exam_date ? helpers.getDate(exam.exam_date) : '' }}</td>
      <td>{{ exam.line }} / {{ exam.place }}</td>
      <td>
        <span>
          {{ exam.room }}
        </span>
      </td>
      <td>{{ helpers.getLocalizedField(exam, 'category')}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'schedule-table',

    props: ['exams'],

    computed: {
      ...mapGetters({
        'user': 'auth/user'
      })
    }
  };
</script>
<style scoped>
  td{
    word-break: break-all;
  }
  .table thead th {
    vertical-align: top;
  }
</style>
