$(document).ready(function () {
  // 'use strict';

  // Navigations on scroll
  var c, currentScrollTop = 0;

  $(window).scroll(function () {

    if ($(window).scrollTop() < $('.navbar__md .navbar').height() || $(window).scrollTop() < $('.navbar__lg').height() || $(window).scrollTop() < $('.navbar__sm').height()) {

      $(".navbar, .navbar__md.navigation-overlay").css({
        'backgroundColor': ' rgba(251, 251, 251, .85)',
        'box-shadow': 'unset'
      });
    } else {

      $(".navbar, .navbar__md.navigation-overlay").css({
        'backgroundColor': ' rgba(251, 251, 251, 1)',
        'box-shadow': '0 .125rem .25rem rgba(0,0,0,.075)'
      });
    }

    //  Small navigation on scroll
    var a = $(window).scrollTop();
    var b = $('.navbar__sm').height();

    currentScrollTop = a;

    if (c < currentScrollTop && a > b + b) {
      $('.navbar__sm').addClass("scrollUp");
    } else if (c > currentScrollTop && !(a <= b)) {
      $('.navbar__sm').removeClass("scrollUp");
    }
    c = currentScrollTop;
  });



  $('#back-bottom a').on('click', function () {
    if ($('html, body').get(0).scrollHeight - $(window).scrollTop() === $(window).height()) {
      $('body,html')
        .animate({
          scrollTop: 0
        }, 800);
      return false;
    }
    if ($(window).scrollTop() - 250 < $(window).height() * 95 / 100) {
      $('body,html')
        .animate({
          scrollTop: $(window).scrollTop() + 250
        }, 300);
      return false

    } else {
      $('body,html')
        .animate({
          scrollTop: $('html, body').get(0).scrollHeight
        }, 300);
      return false;
    }
  })


  // Messages

  // converasion height
  var smmin = window.matchMedia("only screen and (min-width: 576px) ")


  // messages hover effect
  $(".msg__item")
    .mouseenter(function () {
      // corners
      $(this).prevAll().removeClass("border-right-top-rounded-c");
      $(this).nextAll().removeClass("border-right-bottom-rounded-c");
      $(this).prevAll().removeClass("border-right-bottom-rounded-c");
      $(this).nextAll().removeClass("border-right-top-rounded-c");

      $(this).prev().addClass("border-right-bottom-rounded");
      $(this).next().addClass("border-right-top-rounded");
    })
    .mouseleave(function () {
      $(this).prev().removeClass("border-right-bottom-rounded");
      $(this).next().removeClass("border-right-top-rounded");
    })

    .on('click', function (e) {

      $(this).addClass('active')
      $(this).prevAll().removeClass('active')
      $(this).nextAll().removeClass('active')
      $(this).prev().addClass("border-right-bottom-rounded-c");
      $(this).next().addClass("border-right-top-rounded-c");

      $('.collapse').collapse('hide');
    });



  // Messages dropdown, arrow
  if (smmin.matches) {
    $('#message__list').on('shown.bs.collapse', function (e) {
      $(e.target).
      closest('.msg__item')
        .find('.date, .messages-count')
        .css({ 'display': 'none', 'transition': 'all 1s ease-in-out' })

      $(e.target)
        .closest('.msg__item')
        .find('.msg__conversation-show')
        .css({ 'position': 'relative', 'padding-left': '3.6rem', 'transition': 'all .5s ease-in-out' })
    })
    $('#message__list').on('hidden.bs.collapse', function (e) {
      $(e.target)
        .closest('.msg__item')
        .find('.msg__conversation-show')
        .css({ 'position': 'relative', 'padding-left': '0', 'transition': 'all .6s  ' })

      $(e.target)
        .closest('.msg__item')
        .find('.date, .messages-count')
        .css({ 'display': 'unset', 'transition': 'all 1s' })

    })
  }



  // Registrations
  $('#modules').on('show.bs.collapse', function (e) {
    $(e.target).
    closest('.module')
      .addClass('active')
  })

  $('#modules').on('hidden.bs.collapse', function (e) {
    $(e.target).
    closest('.module')
      .removeClass('active')
  })

  // Evaluations
  $('#semesters, #presentation-subjects, #payments').on('show.bs.collapse', function (e) {
    $(e.target).
    closest('.card')
      .addClass('active')
  })

  $('#semesters, #presentation-subjects, #payments').on('hidden.bs.collapse', function (e) {
    $(e.target).
    closest('.card')
      .removeClass('active')
  })


})

function trimText(selector, limit) {
  var text = selector.html(), trim;

  selector.each(function () {
    if ($(this).text().length > limit) {
      trim = $(this).html().substr(0, limit);
      $(this).html(trim);
    };
  });


  $('.js-expander-button').on("click", function () {
    if ($(this).attr("aria-pressed") == "false") {
      // show content
      $(this).attr("aria-pressed", "true");
      $('.note-body').html(text)

    } else {
      // hide content
      $(this).attr("aria-pressed", "false");
      $('.note-body').html(trim)
    }
    $(this).text() === i18n.t('exam-schedule.read-more') ? $(this).text(i18n.t('exam-schedule.close')) : $(this).text(i18n.t('exam-schedule.read-more'));
  });
}




function menuToggle(param) {
  if (param === 1) {
    $('#myNav').css({
      'height': '95%',
      'top': '50%',
      'left': '50%',
      'transform': 'translate(-50%,-50%)'
    })
    var navbarWidth = $('.navbar__md.navbar').width();
    $('#myNavmd').animate({
      'margin': '8px -12px 0 ',
      'top': '0',
      'width': navbarWidth,
      'height': window.innerHeight * 20 / 100
    }, 400, '', function () { })

    $('#myNavmd').on('show.bs.dropdown', function () {
      $('#myNavmd').css({ 'top': '0', 'transition': 'none', 'height': 'auto' })
    })
    $('#myNavmd').on('hide.bs.dropdown', function () {
      $('#myNavmd').css({ 'top': '0', 'transition': 'none' })
    })
    $('#myNavmd').on('hidden.bs.dropdown', function () {
      $('#myNavmd').css({ 'top': '0', 'transition': 'none' })
    })



  } else {
    $('#myNav').css({
      'height': '0%',
      'top': '-64%'
    })

    $('#myNavmd').css({
      'top': '-250px',
      '-webkit-transition': '0.6s cubic-bezier(0.86, 0, 0.07, 1)',
      '-o-transition': '0.6s cubic-bezier(0.86, 0, 0.07, 1)',
      'transition': '0.6s cubic-bezier(0.86, 0, 0.07, 1)',
    })
    $('#myNavmd').on('hidden.bs.dropdown', function () {
      $('#myNavmd').css({
        'top': '-250px',
        '-webkit-transition': '0.6s cubic-bezier(0.86, 0, 0.07, 1)',
        '-o-transition': '0.6s cubic-bezier(0.86, 0, 0.07, 1)',
        'transition': '0.6s cubic-bezier(0.86, 0, 0.07, 1)',
      })
    })

  }
}



