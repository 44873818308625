<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>

        <!-- Exam Schedule -->
        <section class="px-0 mx-0 px-xl-5 mx-xl-5  mt-0 mt-md-5 mb-3">
          <div class="container-fluid px-0 px-sm-3">
            <!-- Instruction -->
            <div class="row">
              <div class="col">
                <div class="note bg-light">
                  <div class="note-body">
                    <div v-if="examInstructions" class="exam-instruction js-expander-more expander_more" 
                    v-html="helpers.getLocalizedField(examInstructions,'instruction')"
                    ></div>
                  </div>
                  <!-- <div class="note-footer">
                    <span class="btn btn-primary-red-10 js-expander-button" aria-pressed="false">{{ $t('exam-schedule.read-more') }}</span>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="locale === 'ka' && getDistanceRules() !== ''">
              <div class="col">
                <div class="note bg-light">
                  <div class="note-body">
                    <div class="exam-instruction2">
                      {{ $t('exam-schedule.please-see-distance-rules') }}
                      <a target="_blank" :href="getDistanceRules()">{{ $t('exam-schedule.on-link') }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col text-center mb-4">
                <div class="exam-instruction2">
                  {{ $t('exam-schedule.video-instructions') }}
                  <template v-if="locale === 'ka'">
                    <br />
                    <a target="_blank" href="https://files.ug.edu.ge/UG_comp_exams.mp4">{{ $t('exam-schedule.computer-exam-instruction') }}</a>
                    <br />
                    <a target="_blank" href="https://files.ug.edu.ge/planchet_geo.mp4">{{ $t('exam-schedule.drawingboard-instruction') }}</a>
                  </template>
                  <template v-else>
                    <br />
                    <a target="_blank" href="https://files.ug.edu.ge/ug_exams_en.mp4">{{ $t('exam-schedule.computer-exam-instruction') }}</a>
                    <br />
                    <a target="_blank" href="https://files.ug.edu.ge/planchet_eng.mp4">{{ $t('exam-schedule.drawingboard-instruction') }}</a>
                  </template>
                </div>
              </div>
            </div>

            <!-- Schedule -->

            <div class="row align-items-center justify-content-center mt-4 pb-5">
              <div class="col-9 col-sm-12 text-center mb-4  ">
                <h3>{{$t('exam-schedule.follow-link')}} <a target='_blank' href='https://exam.ug.edu.ge/' class='link-primary'>{{$t('exam-schedule.link')}}</a></h3>
              </div>

              <h2 class="my-5" v-if="this.exams.length === 0">{{ $t('exam-schedule.exams-not-found') }}</h2>

              <div v-if="hasExams()" class="col-12 mt-3">
                <section class="exam-schedule">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <a @click="chosenExamType = examType" v-for="(examType, i) of examTypes"
                         v-if="getExams(examType, false).length > 0"
                         :class="['nav-link', { active: chosenExamType === examType }]"
                         :id="'nav-exam-type-'+i"
                         data-toggle="tab"
                         :href="'#tab-exam-type-'+i"
                         role="tab"
                         :aria-controls="'nav-exam-type-'+i"
                         aria-selected="true">{{ helpers.getLocalizedField(examType, 'exam') }}</a>
                    </div>
                  </nav>
                  <div
                    class="tab-content"
                    id="nav-tabContent">
                    <div
                      v-if="getExams(examType, false).length > 0"
                      v-for="(examType, i) of examTypes"
                      :class="['tab-pane', { active: chosenExamType === examType }]"
                      :id="'tab-exam-type-'+i"
                      role="tabpanel"
                      aria-labelledby="nav-midterm-first-tab">
                      <!-- schedule -->
                      <schedule-table :exams="currentExams"></schedule-table>
                    </div>
                  </div>
                </section>
              </div>

              <div class="col-12 mt-5" v-if="getExams(null, true).length > 0">
                <section class="exam-schedule">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab1" role="tablist">
                      <a class="nav-link active"
                         id="nav-additional-exams"
                         data-toggle="tab"
                         href="#tab-additional-exams"
                         role="tab"
                         aria-controls="nav-additional-exams"
                         aria-selected="true">{{ $t('exam-schedule.additional-exams') }}</a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent1">
                    <div
                      
                      class="tab-pane active"
                      id="tab-additional-exams"
                      role="tabpanel"
                      aria-labelledby="nav-midterm-first-tab">
                      <!-- schedule -->
                      <schedule-table :exams="getExams(null, true)"></schedule-table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

      </main>
      <!-- MAIN CONTETN end -->
    </div>
  </div>
</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import { mapGetters } from 'vuex';
import SkList from '../components/skeletons/sk-list';
import ScheduleTable from '../components/exam-schedule/schedule-table';
import navHeader from '../components/navbar/nav-header';
import breadcrumb from '../components/page/breadcrumb';

const $ = require('../../static/vendor/jQuery/jquery-3.5.1.min.js');
require('../../static/js/script.js');

export default {
  name: 'exam-schedule',
  components: { ScheduleTable, SkList, sidebar, appHeader, pageHeader, navHeader, breadcrumb },

  data() {
    return {
      chosenExamType: null,
      examInstructions:null
    
    }
  },

  computed: {
    currentExams() {
      return this.getExams(this.chosenExamType, false);
    },

    pageData() {
      return {
        title: this.$t('exam-schedule.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
        },
          {
          icon: '',
          title: this.$t('exam-schedule.title'),
          link: '/exam-schedule',
          isActive: true,
        }],
      };
    },
    ...mapGetters({
      exams: 'exam/exams',
      examTypes: 'exam/examTypes',
      user: 'auth/user',
      locale: 'language/locale',
     
    })
  },

  methods: {
    getDistanceRules() {
      // if(this.user.schoolID == '13') return this.$t('exam-schedule.distance-rules-samartali-url');
      // if(this.user.schoolID == '17') return this.$t('exam-schedule.distance-rules-socialuri-url');
      // if(this.user.schoolID == '4') return this.$t('exam-schedule.distance-rules-teqnologiebi-url');
      // if(this.user.schoolID == '20') return this.$t('exam-schedule.distance-rules-humanitaruli-url');
      // if(this.user.schoolID == '32') return this.$t('exam-schedule.distance-rules-biznesi-url');

      return '';
    },
 
    getExams(type, additionalExams = false) {
      if(additionalExams)
        return this.exams.filter(o => o.schedule_typeID != 1);

      if(type)
        return this.exams.filter(o => o.schedule_typeID == 1 && o.exam_numberID == type.exam_numberID);

      return [];
    },

    hasExams() {
      return this.exams.filter(o => o.schedule_typeID == 1).length > 0;
    }
  },

  mounted() {
    this.$store.dispatch('exam/loadExams').then(() => {
      this.$store.dispatch('exam/loadExamTypes').then(() => {
        let types = this.examTypes;

        for(const type of types) {
          if(this.getExams(type, false).length > 0) {
            this.chosenExamType = type;
            break;
          }
        }

       
      });
    });

 
   
    this.$store.dispatch('exam/getInstructions').then(response=>{  

      this.examInstructions=response.data.data
   
   })

    let words;
    if (window.innerWidth>991){
      words = 2910;
    }else{
      words = 2900;
    } 



  


  }
};
</script>
<style scoped>
.note-footer{
  padding-top: .5rem;
}

.exam-instruction2 {
  font-size: 22px;
}

</style>
